import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#236fa6',
      background: '#00456B',
      hover: '#1C5985',
      text: '#FFF',
    },
    secondary: {
      main: '#EDF1F8',
      light: '#e5e5e5',
      background: '#FFF',
      borderColor: '#236FA6',
      hover: '#236FA6',
      text: '#236FA6'
    },
    tertiary: {
      main: '#236FA6',
      background: '#236FA6',
      borderColor: '#236FA6',
      hover: '#236FA6',
      text: '#FFF'
    },
    error: {
      main: '#BC3832',
      hover: '#962D28',
      light: '#ffcdd2',
      background: '#ffcdd2',
      text: '#BC3832'
    },
    warning: {
      main: "#ffc107",
      text: "#ffc107"
    },
    success: {
      main: "#06893A",
      text: "#06893A"
    },
    info: {
      main: "#06893A",
      text: "#06893A"
    },
    text: {
      main: '#FFF'
    },
    approachDropdown: {
      background: {
        OFF: "#343a40 !important",
        ALL_HEAVY: '#AFBD22 !important',
        DEFAULT: '#ffc107 !important'
      },
      border: {
        OFF: '#000 !important', 
        ALL_HEAVY: '#000 !important',
        DEFAULT: '#000 !important'
      },
      color: {
        OFF: '#FFF !important',
        ALL_HEAVY: '#000 !important',
        DEFAULT: '#000 !important'
      }
    }
  },
  shape: {
    borderRadius: 4
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',

    h1: {
      fontSize: "48px",
      fontWeight: "600",
      lineHeight: "60px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    h2: {
      fontSize: "32px",
      fontWeight: "600",
      lineHeight: "42px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "26px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "26px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "24px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    h6: {
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "18px",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
    },
    p: {
      fontWeight: "400",
      verticalAlign: "center",
      letterSpacing: "-0.5px",
      margin: 0
    }
  }
});

export default theme;