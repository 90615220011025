import React, { useState } from 'react';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { useSelector, useDispatch } from 'react-redux';

import { setAllApproachStatuses } from '@actions';
import { AllApproachControlButton } from './Button';

const AllApproachStatusDropdown = () => {
  const [approachMenu, setApproachMenu] = useState(null);
  const dispatch = useDispatch();

  const safetyCentres = useSelector(state => state.configuration.safetyCentres);
  const selected = useSelector(state => state.configuration.selected);
  const safetyCentre = safetyCentres ? safetyCentres[selected] : null;

  const approachMenuClick = (event) => {
    if (event) {
      setApproachMenu(event.currentTarget);
    }
  };

  const approachMenuClose = () => {
    setApproachMenu(null);
  };

  const off = () => {
    const scid = safetyCentre.id;
    setTimeout(() => {
      dispatch(setAllApproachStatuses(scid, 'OFF'));
    }, 1000);
  };
  
  const on = () => {
    const scid = safetyCentre.id;
    setTimeout(() => {
      dispatch(setAllApproachStatuses(scid, 'ON', 'DEFAULT'));
    }, 1000);
  };

  if (!safetyCentre) {
    return null;
  }
  const allApproachesOn = safetyCentre.approaches.every(
    (a) => a.status === 'ON'
  );
  const anyApproachesOn = safetyCentre.approaches.some(
    (a) => a.status === 'ON'
  );
  const allAppraoachesDefault = safetyCentre.approaches.every(
    (a) => a.screeningRule === 'DEFAULT'
  );
  const allApproachesOff = safetyCentre.approaches.every(
    (a) => a.status === 'OFF'
  );
  const status = () => {
    if (allApproachesOff) {
      return "OFF";
    } else if (allApproachesOn && allAppraoachesDefault) {
      return "DEFAULT";
    } else {
      return "ALL_HEAVY";
    }
  }

  return (
    <>
      <AllApproachControlButton approachMenuClick={approachMenuClick} iconText={anyApproachesOn ? 'ON' : 'OFF'}  status={status()} />

      <Popover
        open={Boolean(approachMenu)}
        anchorEl={approachMenu}
        onClose={approachMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8 w-72',
        }}
      >
        <MenuItem 
          onClick={() => {
            on();
            approachMenuClose();
          }}
          role="button"
        >
          <ListItemText primary="ON" />
        </MenuItem>
        <MenuItem 
          onClick={() => {
            off();
            approachMenuClose();
          }}
          role="button"
        >
          <ListItemText primary="OFF" />
        </MenuItem>
      </Popover>
    </>
  )
};

export default AllApproachStatusDropdown;